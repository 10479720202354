import Swiper from "swiper"
import { find } from "utils/dom"

export default function () {
	const Home = find("[js-page='home']")
	if (!Home) return

	const swiper = new Swiper(".carrosselCategoria", {
		slidesPerView: 'auto',
		spaceBetween: 30,
		autoplay: true,
		delay: 200000,
		loop: true,
		disableOnInteraction: true,
		reverseDirection: false,
		navigation: {
			nextEl: ".swiper-categoria-home-next",
			prevEl: ".swiper-categoria-home-prev",
		}
	});

	const data = new Date("2024-12-31T23:59:59").getTime();
	function contagemRegressiva() {
		const now = new Date().getTime();
		const distance = data - now;


		const days = Math.floor(distance / (1000 * 60 * 60 * 24));
		const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));


		document.getElementById("days").innerText = days;
		document.getElementById("hours").innerText = hours;
		document.getElementById("minutes").innerText = minutes;


		if (distance < 0) {
			clearInterval(interval);
			document.getElementById("days").innerText = "0";
			document.getElementById("hours").innerText = "0";
			document.getElementById("minutes").innerText = "0";
		}
	}

	const interval = setInterval(contagemRegressiva, 60000);
	//contagemRegressiva();

	const swiperCenter = () => {
		const swipers = document.querySelectorAll('.mySwiper')
		swipers.forEach(el => {
			if (window.screen.width < 600) {
				el.setAttribute('centered-slides', true)
			}
		})
	}

	swiperCenter()
}
import { find, findAll } from "utils/dom"
import { formatMilliseconds, formatVotingDate } from "../utils/formats"
import "toastify-js/src/toastify.css"

export default function () {
	const Indicacao = find("[js-page='indicacao']")
	if (!Indicacao) return

	let currentIndicationStep = 1
	let indicatedQuantity = 0
	let votingDateInterval

	function alertRequiredFields(fields, txt, verify = true) {
		fields.map(input => {
			const inputParent = input.parentNode
			const requiredFieldParagraph = inputParent.parentNode.querySelector('p.text-paragraph-4')

			

			requiredFieldParagraph?.remove()

			if ((verify && !input.value) || !verify) {

				console.log(verify)
				inputParent.insertAdjacentHTML('afterend', `
					<p class="text-paragraph-4" style="color: rgb(220, 38, 38)">
						${txt}
					</p>
				`)
			}
		})
	}


	function handleChangeSelect(ev) {
		const select = ev.target
		const selectValue = select.value
		const inputField = select.closest('[js-field]').querySelector('.Input')

		if (selectValue === 'others') {
			inputField.querySelector('input').value = ''
			inputField.classList.remove('hidden')
		} else {
			inputField.classList.add('hidden')
			inputField.querySelector('input').value = selectValue
		}
	}

	function verifyFieldsOnSubmit() {
		const formElement = find('form')
		const inputFields = findAll('[data-field-required="true"]')
		const inputValues = inputFields.map(v => v.value)
		const hasEmptyInput = inputFields.some(input => !input.value)


		inputFields
			.filter(input => input.name.startsWith("valor-indicado"))
			.map(input => {
				const count = inputValues.filter(i => i === input.value && input.value || '').length

				if (count > 1) {
					alertRequiredFields([input], 'Indicado repetido', false)
				}
			})

		inputFields.filter((input) =>{
			if (input.type == 'checkbox'){
				return input;
			}
		}).map((input)=>{
			const is_checked = (input.checked?true:false)
			if(!is_checked){
				console.log("cheskss")
				alertRequiredFields([input], 'Campo obrigatório', false)
			}
		})

		if (hasEmptyInput) {
			alertRequiredFields(inputFields, 'Campo obrigatório')

		}
		else if (Indicacao.id === 'indicacao-superjuri') {
			const repeatedFields = inputFields
				.filter(input => input.name.startsWith("valor-indicado"))
				.map(input => {
					const count = inputValues.filter(i => i === input.value && input.value || '').length

					if (count > 1)
						alertRequiredFields([input], 'Indicado repetido', false)

					return count
				})

			!repeatedFields.some(i => i > 1) && formElement.submit()
		}
	}

	function verifyGameFields() {
		const gameInputs = findAll('[name^="valor-jogo"]')

		return gameInputs.map(input => {
			const categoryContent = input.closest('[data-category-content]')
			const indicated = categoryContent.querySelector('[name^="valor-indicado"]').value

			if (indicated && !input.value) {
				alertRequiredFields([input], 'Campo obrigatório')
				return true

			} else return false
		})
	}

	function updateIndicationSteps() {
		const indicationStepMarks = findAll("[data-step-mark]")
		const indicationSteps = findAll("[data-step-content]")

		indicationSteps?.map(step => step.classList.add('hidden'))
		indicationSteps.find((step => +step.dataset.stepContent === currentIndicationStep))
			?.classList.remove('hidden')

		indicationStepMarks?.map(el => {
			const step = +el.dataset.stepMark

			if (step <= currentIndicationStep) {
				el.style.background = 'rgb(239, 187, 110)'
				el.style.color = '#000'
			}
		})
	}

	function handleChangeIndicationSteps() {
		const nextStepAction = find('[data-next-step]')

		nextStepAction.addEventListener('click', () => {
			const hasMandatoryField = verifyGameFields().some(v => v)
			const stickSimple = find('[data-stick-simple]')
			const stickComplete = find('[data-stick-complete]')

			if (indicatedQuantity === 0) {
				const emptyField = findAll('[name^="valor-indicado"]').find(f => !f.value)
				alertRequiredFields([emptyField], 'Campo obrigatório')

				return
			}

			if (!hasMandatoryField) {
				currentIndicationStep++
				updateIndicationSteps()

				stickSimple.classList.add('hidden')
				stickComplete.classList.remove('hidden')

				find('#banner_votacao').scrollIntoView({
					behavior: 'smooth',
					block: 'start'
				})
			}
		})
	}

	function handleFilterCategory() {
		numbersHeader()
		numbersIndicated()

		const categoryButtons = findAll('[data-category-button]')
		const categoriesContent = findAll('[data-category-content]')
		const buttons = categoryButtons.map(header => header.querySelector('.frame button'))

		buttons.forEach(button => {
			button.querySelector('span').innerText.toLowerCase() === 'popular'
				? button.classList.add('ativo')
				: ''

			button.addEventListener('click', () => {
				buttons.map(btn => btn.classList.remove('ativo'))
				button.classList.add('ativo')
			})
		})

		categoryButtons.forEach(btn => {
			btn.addEventListener('click', () => {
				btn.classList.add('ativo')

				categoriesContent.forEach(cat => {
					if (btn.dataset.categoryButton === cat.dataset.categoryContent) {
						cat.classList.add('block')
						cat.classList.remove('hidden')
					}
					else if (btn.dataset.categoryButton === '2') {
						cat.classList.remove('hidden')
					}
					else {
						cat.classList.add('hidden')
					}
				})

				numbersHeader()
				numbersIndicated()
			})
		})
	}

	function numbersHeader() {
		const allCategorias = findAll('[data-category-content]')
		const visibleCategories = allCategorias.filter(cat => !cat.classList.contains('hidden'))

		const qtdCategorias = document.querySelector('[data-qtd-itens-categoria]')
		const qtdCategoriaTotal = document.querySelector('[data-total-categoria]')

		if (!qtdCategorias && !qtdCategoriaTotal) return
		qtdCategorias.innerText = visibleCategories.length
		qtdCategoriaTotal.innerText = allCategorias.length
	}

	function numbersIndicated() {
		const visibleIndicatedContainer = findAll('[data-category-content]:not(.hidden)')

		setTimeout(() => {
			const allIndicated = visibleIndicatedContainer.map(container =>
				container.querySelector('[name^="valor-indicado"]').value
			).filter(value => value)

			const qtdTotalIndicated = find('[data-total-indicated]')
			const qtdItensIndicated = find('[data-qtd-itens-indicated]')

			indicatedQuantity = allIndicated.length

			qtdItensIndicated.innerText = indicatedQuantity
			qtdTotalIndicated.innerText = visibleIndicatedContainer.length
		}, 100)
	}

	// Voto concluído
	function attCountdown(votingDate, element, container) {
		const now = new Date()
		const differenceTime = votingDate - now

		if (differenceTime <= 0) {
			clearInterval(votingDateInterval)
			container?.remove()

		} else {
			element.textContent = formatMilliseconds(differenceTime)
		}
	}

	function verifyVotingDate() {
		const countdownContainer = find("[data-date-released]")
		const dateValue = countdownContainer.dataset.dateReleased

		if (!dateValue || dateValue === "None") {
			countdownContainer.remove()
		} else {
			const formatedDate = formatVotingDate(dateValue)
			const countdownElement = countdownContainer.querySelector('span')

			votingDateInterval = setInterval(() => attCountdown(formatedDate, countdownElement, countdownContainer), 1000);
		}
	}

	// =================
	document.addEventListener('DOMContentLoaded', () => {
		const selectField = findAll('[js-field] select')
		const submitButton = find('[data-submit-form]')

		if (Indicacao.id === 'indicacao-publica') {
			handleChangeIndicationSteps()
			updateIndicationSteps()
			handleFilterCategory()

			findAll('[name^="valor-indicado"]')?.map(input =>
				input.addEventListener('input', numbersIndicated)
			)

			findAll('[js-select]')?.map(input =>
				input.addEventListener('change', numbersIndicated)
			)

			findAll('[data-cep-mask]')?.map(input => IMask(input, {
				mask: '00000-000'
			}))
		}

		if (Indicacao.id === 'indicacao-concluida') {
			currentIndicationStep = 3
			updateIndicationSteps()
			verifyVotingDate()
		}

		selectField?.forEach(select => select.addEventListener('change', handleChangeSelect))
		submitButton?.addEventListener('click', verifyFieldsOnSubmit)
	})
}
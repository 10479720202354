export function formatVotingDate(dateString) {
	const months = {
		"Janeiro": 0,
		"Fevereiro": 1,
		"Março": 2,
		"Abril": 3,
		"Maio": 4,
		"Junho": 5,
		"Julho": 6,
		"Agosto": 7,
		"Setembro": 8,
		"Outubro": 9,
		"Novembro": 10,
		"Dezembro": 11
	}

	const [day, , month, , year] = dateString.split(" ")
	const [hour, minutes] = dateString.split(" às ")[1].split(':').map(Number)

	return new Date(+year, months[month], +day, hour, minutes)
}

export function formatMilliseconds(ms) {
	const seconds = Math.floor(ms / 1000);
	const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
	const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
	const sec = String(seconds % 60).padStart(2, '0');

	return `${hours}:${minutes}:${sec}`;
}